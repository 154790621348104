//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { validationMixin } from 'vuelidate'
import { helpers, required, minLength, maxLength, email } from 'vuelidate/lib/validators'
import Modal from '~/components/UI/Modal/Modal.vue'
import PasswordInput from '~/components/UI/PasswordInput.vue'
import { SET_TOKEN } from '~/store/auth/types.auth'
import validationWatcher from '~/mixins/validationWatcher'

const emailValidationText = helpers.regex('email', /^[a-zA-Z0-9._@\-\s]*$/)
const containsPasswordRequirement = helpers.regex('The password requires an uppercase, lowercase, number', /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/)

export default {
  name: 'LoginModal',
  components: { Modal, PasswordInput },
  mixins: [validationMixin, validationWatcher],
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
    }
  },
  validations: {
    form: {
      email: { required, email, minLength: minLength(5), maxLength: maxLength(64), emailValidationText },
      password: { required, minLength: minLength(8), maxLength: maxLength(36), containsPasswordRequirement },
    },
  },
  methods: {
    handleClick() {
      this.$refs.modal.onOpen()
    },
    onClose() {
      this.$refs.modal.onClose()
    },
    onClear() {
      this.form = {
        email: '',
        password: '',
      }
    },
    onForgot() {
      this.$router.push({ path: 'auth', query: { type: 'restore' } })
    },
    onLogin() {
      this.setValidationSettings(true)
      if (!this.checkForm()) return
      let form = this.form;
      if (!this.isAuth) {
        form = {
          ...this.form,
          uuid: localStorage.getItem('tempUser')
        }
      }
      this.$api.auth
        .login(form)
        .then((payload) => {
          this.$refs.modal.onClose()
          const { token } = payload.data
          this.$store.commit(`auth/${SET_TOKEN}`, { token })
          //сгенерировать новый и перезапрсоить продукты
          this.onClear()
          localStorage.removeItem('tempUser')
          this.$nuxt.refresh()
        })
        .catch((err) => {
          if(err.response.data.message === 'userIsBlockedOrNotActive') {
            this.errors = {
              message: this.$t('userIsBlockedOrNotActive'),
            }
          } else {
            this.errors = {
              message: err.response.data.message,
            }
          }
        })
        .finally(() => {
          this.setValidationSettings(false)
          this.$store.dispatch('cart/FETCH_CART_PRODUCTS')
        })
    },
    googleOauth() {
      window.location.href = `${this.$config.apiURL}/${this.$i18n.locale}/oauth/google-redirect`
    },
  },
}
